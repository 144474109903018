import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from 'shared/api/axios'

export const usePlatformsQuery = () => {
  const query = useQuery({
    queryKey: ['platforms'],
    queryFn: async () => {
      const response = await axiosInstance.get<PlatformsResponse>(
        '/api135a/taxonomy_term/platforms'
      )
      return response.data
    },
    staleTime: Infinity,
    select: (data) =>
      data.data.map((item) => ({
        id: item.id,
        name: item.attributes.name,
      })),
  })

  return query
}

interface PlatformsResponse {
  jsonapi: Jsonapi
  data: Datum3[]
  meta: Meta6
  links: Links
}

interface Meta6 {
  count: number
}

interface Datum3 {
  type: string
  id: string
  links: Links
  attributes: Attributes
  relationships: Relationships
}

interface Relationships {
  vid: Vid
  revision_user: Revisionuser
  parent: Parent
  entitygroupfield: Entitygroupfield
  content_translation_uid: Contenttranslationuid
}

interface Contenttranslationuid {
  data: Datum2 | null
  links: Links2
}

interface Datum2 {
  type: string
  id: string
  meta: Meta5
}

interface Meta5 {
  drupal_internal__target_id: number
}

interface Entitygroupfield {
  data: any[]
  links: Links2
}

interface Parent {
  data: Datum[]
  links: Links2
}

interface Datum {
  type: string
  id: string
  meta: Meta4
}

interface Meta4 {
  links: Links3
}

interface Links3 {
  help: Help
}

interface Help {
  href: string
  meta: Meta3
}

interface Meta3 {
  about: string
}

interface Revisionuser {
  data: null
  links: Links2
}

interface Vid {
  data: Data
  links: Links2
}

interface Links2 {
  related: Self
  self: Self
}

interface Data {
  type: string
  id: string
  meta: Meta2
}

interface Meta2 {
  drupal_internal__target_id: string
}

interface Attributes {
  drupal_internal__tid: number
  drupal_internal__revision_id: number
  langcode: string
  revision_created: string
  revision_log_message: null
  status: boolean
  name: string
  description: Description | null
  weight: number
  changed: string
  default_langcode: boolean
  revision_translation_affected: boolean
  path: Path
  content_translation_source: string
  content_translation_outdated: boolean
  content_translation_created: null | string
}

interface Path {
  alias: null
  pid: null
  langcode: string
}

interface Description {
  value: string
  format: string
  processed: string
}

interface Jsonapi {
  version: string
  meta: Meta
}

interface Meta {
  links: Links
}

interface Links {
  self: Self
}

interface Self {
  href: string
}
