import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { axiosInstance } from 'shared/api/axios'

export const useLoginStatusQuery = () => {
  const { enqueueSnackbar } = useSnackbar()

  const query = useQuery({
    queryKey: ['login-status'],
    staleTime: Infinity,
    queryFn: async () => {
      try {
        const response = await axiosInstance.get<1 | 0>(
          '/user/login_status?_format=json'
        )
        return response.data
      } catch (e) {
        enqueueSnackbar('Login status fetching error', {
          variant: 'error',
        })
        throw new Error('Login status fetching error', {
          cause: e,
        })
      }
    },
    retry: 1,
  })

  return query
}

interface LoginParams {
  name: string
  pass: string
}

export const useLoginMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const mutation = useMutation({
    mutationKey: ['login'],
    mutationFn: (params: LoginParams) =>
      axiosInstance.post('/user/login?_format=json', params),
    onSuccess: () => {
      queryClient.setQueryData(['login-status'], 1)
    },
    onError: () => {
      enqueueSnackbar('Login failed', {
        variant: 'error',
      })
    },
  })

  return mutation
}
