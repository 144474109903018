import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { axiosInstance } from 'shared/api/axios'

interface Schema {
  meta: {
    links: {
      me: {
        meta: {
          id: string
        }
      }
    }
  }
}

interface UserInfo {
  data: {
    attributes: {
      display_name: string
      mail: string
    }
  }
}

export const useUserInfoQuery = () => {
  const { enqueueSnackbar } = useSnackbar()

  const query = useQuery({
    queryKey: ['user'],
    staleTime: Infinity,
    queryFn: async () => {
      const schema = await axiosInstance.get<Schema>('/en/api135a/')

      try {
        if (schema.data.meta.links.me.meta.id) {
          const user = await axiosInstance.get<UserInfo>(
            `/en/api135a/user/user/${schema.data.meta.links.me.meta.id}`
          )

          if (user.data.data.attributes.display_name) {
            return user.data.data.attributes
          }
        }
      } catch (e) {
        enqueueSnackbar('User info fetching error', {
          variant: 'error',
        })
        throw new Error('User info fetching error', {
          cause: e,
        })
      }
    },
    retry: 0,
  })

  return query
}
