import React from 'react'
import { useLoginStatusQuery } from './model'
import { LoginFormPage } from './LoginFormPage'

interface AuthProviderProps {
  children: JSX.Element
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { data: loginStatus } = useLoginStatusQuery()

  if (loginStatus === undefined) {
    return null
  }

  if (loginStatus === 0) {
    return <LoginFormPage />
  }

  return children
}
