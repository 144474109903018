import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { useLoginMutation } from './model'
import s from './LoginFormPage.module.css'
import { Button, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

const validationSchema = Yup.object({
  name: Yup.string().required(),
  pass: Yup.string().required(),
})

const initialFormValues = {
  name: '',
  pass: '',
}

export const LoginFormPage = () => {
  const { t } = useTranslation()
  const { mutateAsync: login, isPending } = useLoginMutation()

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
    mode: 'onChange',
  })

  return (
    <div className={s.wrapper}>
      <form
        onSubmit={handleSubmit((values) => login(values))}
        className={s.form}
      >
        <TextField
          label={t('username')}
          {...register('name')}
          fullWidth
          sx={{
            mb: 2,
          }}
          autoFocus
        />
        <TextField
          label={t('password')}
          type="password"
          {...register('pass')}
          fullWidth
        />
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          type="submit"
          disabled={isPending}
        >
          {t('continue')}
        </Button>
      </form>
    </div>
  )
}
