import './App.css'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from 'shared/queryClient'
import { Routes } from 'pages'
import { SnackbarProvider } from 'notistack'
import { ProjectProvider } from 'features/head/context'
import { AuthProvider } from 'widgets/auth/AuthProvider'

const App = () => {
  return (
    <SnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ProjectProvider>
            <Routes />
          </ProjectProvider>
        </AuthProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  )
}

export default App
