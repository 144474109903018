import {
  Report,
  ReportAssessments,
  ReportScreenVariant,
  ReportScreenVariantFragment,
} from './types'
import {
  DEFAULT_OFFSET,
  TRANSITION_WIDTH,
  getNearestActionIdInColumn,
} from 'entities/flow'
import i18n from 'shared/i18n/i18n'
import { ProjectData } from 'shared/types/projects'
import { getScreenFragmentsByProjects } from './lib'
import { Datum } from 'entities/assessment/api-types'

interface GenerateReportParams {
  project: ProjectData
  heuristics: Datum[]
}

const getInitialReportAssessments = (): ReportAssessments => ({
  'ux-good': 0,
  'ux-problem-high': 0,
  'ux-problem-low': 0,
  'ux-problem-medium': 0,
  bug: 0,
  task: 0,
})

export const generateReport = async ({
  project,
  heuristics,
}: GenerateReportParams): Promise<Report> => {
  const linkProjects = project.linkProjects

  const reportScreens: Report['screens'] = []

  const screenXCoords = new Set(
    project.flow.screens
      .filter((screen) => Boolean(screen.name))
      .map((screen) => screen.position.x)
  )
  const sortedUniqScreenXCoords = [...screenXCoords.keys()].sort(
    (a, b) => a - b
  )

  const allAssessmentIds: string[] = []
  const finalReportAssessmentIds: string[] = []
  const excludedAssessmentIds: string[] = project.excludedAssessmentIds || []
  const finalReportRelatedAssessmentIds: string[] = []

  sortedUniqScreenXCoords.forEach((xCoord, index) => {
    const currentXScreens = project.flow.screens
      .filter((screen) => screen.position.x === xCoord && Boolean(screen.name))
      .sort((screenA, screenB) => screenA.position.y - screenB.position.y)

    const firstScreen = currentXScreens[0]

    const leftTransitionFromFirstScreen = project.flow.transitions.find(
      (transition) =>
        transition.position.y === firstScreen.position.y &&
        transition.position.x ===
          firstScreen.position.x - DEFAULT_OFFSET - TRANSITION_WIDTH
    )

    const screenVariants: ReportScreenVariant[] = currentXScreens.map(
      (screen, index) => {
        const nearestActionId = getNearestActionIdInColumn({
          transitions: project.flow.transitions,
          position: {
            x: screen.position.x - DEFAULT_OFFSET - TRANSITION_WIDTH,
            y: screen.position.y - 1,
          },
        })

        const actionName = project.transitionComponents.find(
          (component) => component.id === nearestActionId
        )?.name

        const screenFragments = linkProjects
          ? getScreenFragmentsByProjects({
              projects: linkProjects,
              screenId: screen.id,
            })
          : project.screenFragments[screen.id]

        const finalReportScreenFragments =
          project.totalAssessmentScreenFragments?.[screen.id]

        const finalReportFragments: ReportScreenVariantFragment[] | undefined =
          finalReportScreenFragments?.map((screenFragment, index) => {
            const fragmentTotalAssessments: ReportAssessments = {
              'ux-good': screenFragment.assessments.filter(
                (assessment) => assessment.findType === 'ux-good'
              ).length,
              'ux-problem-high': screenFragment.assessments.filter(
                (assessment) =>
                  assessment.type === 'bad' &&
                  assessment.findType === 'ux-problem'
              ).length,
              'ux-problem-low': screenFragment.assessments.filter(
                (assessment) =>
                  assessment.type === 'good' &&
                  assessment.findType === 'ux-problem'
              ).length,
              'ux-problem-medium': screenFragment.assessments.filter(
                (assessment) =>
                  assessment.type === 'medium' &&
                  assessment.findType === 'ux-problem'
              ).length,
              bug: screenFragment.assessments.filter(
                (assessment) => assessment.findType === 'bug'
              ).length,
              task: screenFragment.assessments.filter(
                (assessment) => assessment.findType === 'task'
              ).length,
            }

            return {
              ...screenFragment,
              number: index + 1,
              totalAssessments: fragmentTotalAssessments,
            }
          })

        finalReportScreenFragments?.forEach((fragment) => {
          fragment.assessments.forEach((assessment) => {
            finalReportAssessmentIds.push(assessment.id)

            assessment.relatedAssessmentIds?.forEach((relatedAssessmentId) => {
              finalReportRelatedAssessmentIds.push(relatedAssessmentId)
            })
          })
        })

        const fragments: ReportScreenVariantFragment[] = screenFragments?.map(
          (screenFragment, index) => {
            const screenFragmentAssessmentsWithoutRelatedFinalReport =
              screenFragment.assessments.filter(
                (assessment) =>
                  !finalReportRelatedAssessmentIds.includes(assessment.id)
              )

            const fragmentTotalAssessments: ReportAssessments = {
              'ux-good':
                screenFragmentAssessmentsWithoutRelatedFinalReport.filter(
                  (assessment) => assessment.findType === 'ux-good'
                ).length,
              'ux-problem-high':
                screenFragmentAssessmentsWithoutRelatedFinalReport.filter(
                  (assessment) =>
                    assessment.type === 'bad' &&
                    assessment.findType === 'ux-problem'
                ).length,
              'ux-problem-low':
                screenFragmentAssessmentsWithoutRelatedFinalReport.filter(
                  (assessment) =>
                    assessment.type === 'good' &&
                    assessment.findType === 'ux-problem'
                ).length,
              'ux-problem-medium':
                screenFragmentAssessmentsWithoutRelatedFinalReport.filter(
                  (assessment) =>
                    assessment.type === 'medium' &&
                    assessment.findType === 'ux-problem'
                ).length,
              bug: screenFragmentAssessmentsWithoutRelatedFinalReport.filter(
                (assessment) => assessment.findType === 'bug'
              ).length,
              task: screenFragmentAssessmentsWithoutRelatedFinalReport.filter(
                (assessment) => assessment.findType === 'task'
              ).length,
            }

            return {
              ...screenFragment,
              number: index + 1,
              totalAssessments: fragmentTotalAssessments,
              assessments: screenFragmentAssessmentsWithoutRelatedFinalReport,
            }
          }
        )

        fragments.forEach((fragment) => {
          fragment.assessments.forEach((assessment) => {
            allAssessmentIds.push(assessment.id)
          })
        })

        const screenVariantTotalAssessments = getInitialReportAssessments()

        fragments.forEach((fragment) => {
          screenVariantTotalAssessments.bug += fragment.totalAssessments.bug
          screenVariantTotalAssessments.task += fragment.totalAssessments.task
          screenVariantTotalAssessments['ux-good'] +=
            fragment.totalAssessments['ux-good']
          screenVariantTotalAssessments['ux-problem-high'] +=
            fragment.totalAssessments['ux-problem-high']
          screenVariantTotalAssessments['ux-problem-medium'] +=
            fragment.totalAssessments['ux-problem-medium']
          screenVariantTotalAssessments['ux-problem-low'] +=
            fragment.totalAssessments['ux-problem-low']
        })

        const finalReportScreenVariantTotalAssessments =
          getInitialReportAssessments()

        finalReportFragments?.forEach((fragment) => {
          finalReportScreenVariantTotalAssessments.bug +=
            fragment.totalAssessments.bug
          finalReportScreenVariantTotalAssessments.task +=
            fragment.totalAssessments.task
          finalReportScreenVariantTotalAssessments['ux-good'] +=
            fragment.totalAssessments['ux-good']
          finalReportScreenVariantTotalAssessments['ux-problem-high'] +=
            fragment.totalAssessments['ux-problem-high']
          finalReportScreenVariantTotalAssessments['ux-problem-medium'] +=
            fragment.totalAssessments['ux-problem-medium']
          finalReportScreenVariantTotalAssessments['ux-problem-low'] +=
            fragment.totalAssessments['ux-problem-low']
        })

        return {
          screenId: screen.id,
          number: index + 1,
          actionName: actionName || '',
          name: index === 0 ? i18n.t('mainScreen') : screen.name,
          fragments: fragments.filter(
            (item) =>
              item.totalAssessments.bug ||
              item.totalAssessments.task ||
              item.totalAssessments['ux-problem-high'] ||
              item.totalAssessments['ux-problem-medium'] ||
              item.totalAssessments['ux-problem-low'] ||
              item.totalAssessments['ux-good']
          ),
          finalReportFragments: finalReportFragments?.filter(
            (item) =>
              item.totalAssessments.bug ||
              item.totalAssessments.task ||
              item.totalAssessments['ux-problem-high'] ||
              item.totalAssessments['ux-problem-medium'] ||
              item.totalAssessments['ux-problem-low'] ||
              item.totalAssessments['ux-good']
          ),
          totalAssessments: screenVariantTotalAssessments,
          finalReportTotalAssessments: finalReportScreenVariantTotalAssessments,
        }
      }
    )

    const reportScreenTotalAssessments = getInitialReportAssessments()
    const finalReportScreenTotalAssessments = getInitialReportAssessments()

    screenVariants.forEach((screenVariant) => {
      reportScreenTotalAssessments.bug += screenVariant.totalAssessments.bug
      reportScreenTotalAssessments.task += screenVariant.totalAssessments.task
      reportScreenTotalAssessments['ux-good'] +=
        screenVariant.totalAssessments['ux-good']
      reportScreenTotalAssessments['ux-problem-high'] +=
        screenVariant.totalAssessments['ux-problem-high']
      reportScreenTotalAssessments['ux-problem-medium'] +=
        screenVariant.totalAssessments['ux-problem-medium']
      reportScreenTotalAssessments['ux-problem-low'] +=
        screenVariant.totalAssessments['ux-problem-low']

      if (screenVariant.finalReportTotalAssessments) {
        finalReportScreenTotalAssessments.bug +=
          screenVariant.finalReportTotalAssessments.bug
        finalReportScreenTotalAssessments.task +=
          screenVariant.finalReportTotalAssessments.task
        finalReportScreenTotalAssessments['ux-good'] +=
          screenVariant.finalReportTotalAssessments['ux-good']
        finalReportScreenTotalAssessments['ux-problem-high'] +=
          screenVariant.finalReportTotalAssessments['ux-problem-high']
        finalReportScreenTotalAssessments['ux-problem-medium'] +=
          screenVariant.finalReportTotalAssessments['ux-problem-medium']
        finalReportScreenTotalAssessments['ux-problem-low'] +=
          screenVariant.finalReportTotalAssessments['ux-problem-low']
      }
    })

    if (
      reportScreenTotalAssessments.bug ||
      reportScreenTotalAssessments.task ||
      reportScreenTotalAssessments['ux-problem-high'] ||
      reportScreenTotalAssessments['ux-problem-medium'] ||
      reportScreenTotalAssessments['ux-problem-low'] ||
      reportScreenTotalAssessments['ux-good']
    ) {
      reportScreens.push({
        name: firstScreen.name,
        actionName:
          project.transitionComponents.find(
            (transitionComponent) =>
              transitionComponent.id === leftTransitionFromFirstScreen?.actionId
          )?.name || '',
        number: index + 1,
        screenVariants: screenVariants.filter(
          (item) =>
            item.totalAssessments.bug ||
            item.totalAssessments.task ||
            item.totalAssessments['ux-problem-high'] ||
            item.totalAssessments['ux-problem-medium'] ||
            item.totalAssessments['ux-problem-low'] ||
            item.totalAssessments['ux-good']
        ),
        totalAssessments: reportScreenTotalAssessments,
        finalReportTotalAssessments: finalReportScreenTotalAssessments,
      })
    }
  })

  const totalAssessments: ReportAssessments = getInitialReportAssessments()
  const finalReportTotalAssessments: ReportAssessments =
    getInitialReportAssessments()

  reportScreens.forEach((reportScreen) => {
    totalAssessments.bug += reportScreen.totalAssessments.bug
    totalAssessments.task += reportScreen.totalAssessments.task
    totalAssessments['ux-good'] += reportScreen.totalAssessments['ux-good']
    totalAssessments['ux-problem-high'] +=
      reportScreen.totalAssessments['ux-problem-high']
    totalAssessments['ux-problem-medium'] +=
      reportScreen.totalAssessments['ux-problem-medium']
    totalAssessments['ux-problem-low'] +=
      reportScreen.totalAssessments['ux-problem-low']

    if (reportScreen.finalReportTotalAssessments) {
      finalReportTotalAssessments.bug +=
        reportScreen.finalReportTotalAssessments.bug
      finalReportTotalAssessments.task +=
        reportScreen.finalReportTotalAssessments.task
      finalReportTotalAssessments['ux-good'] +=
        reportScreen.finalReportTotalAssessments['ux-good']
      finalReportTotalAssessments['ux-problem-high'] +=
        reportScreen.finalReportTotalAssessments['ux-problem-high']
      finalReportTotalAssessments['ux-problem-medium'] +=
        reportScreen.finalReportTotalAssessments['ux-problem-medium']
      finalReportTotalAssessments['ux-problem-low'] +=
        reportScreen.finalReportTotalAssessments['ux-problem-low']
    }
  })

  const inProgressAssessmentIds = [...new Set(allAssessmentIds)].filter(
    (assessmentId) =>
      !finalReportRelatedAssessmentIds.includes(assessmentId) &&
      !excludedAssessmentIds.includes(assessmentId)
  )

  const report: Report = {
    heuristics,
    screens: reportScreens,
    totalAssessments,
    finalReportTotalAssessments,
    finalReportProgress: {
      inProgressAssessmentsIds: inProgressAssessmentIds,
      finalReportAssessmentsIds: finalReportAssessmentIds,
      finalReportRelatedAssessmentIds,
    },
  }

  return report
}
