import { AssessmentPanel } from 'widgets/assessment/panel'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ScreenCanvas } from 'widgets/assessment/screen-canvas'
import { useCallback, useEffect, useState } from 'react'
import {
  useProject,
  useProjectId,
  useRemoteProjectRefresh,
} from 'shared/model/projects'
import { TabType } from '../lib'

import styles from './Assessment.module.css'
import { useHasWritePermission } from 'entities/permissions'
import { ProjectHead } from 'features/head/ProjectHead'
import { AssessmentHead } from 'features/assessment/head/AssessmentHead'
import { ReportDetailsContent } from 'widgets/report/ui/ReportDetails/ReportDetailsContent'
import { RemoteProjectMeta } from 'shared/types/projects'
import { useHeuristicsAndTags } from 'entities/assessment'
import { usePlatformsQuery } from 'shared/model/platforms'

export const Assessment = () => {
  const { screenId } = useParams()
  const { data: projectData } = useProject()
  const [fragmentId, setFragmentId] = useState('')

  useRemoteProjectRefresh()

  const hasWritePermission = useHasWritePermission()
  const [tab, setTab] = useState<TabType>(
    hasWritePermission ? 'search' : 'results'
  )
  const [activeHeuristicId, setActiveHeuristicId] = useState('')

  const projectId = useProjectId()
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const goToFlow = useCallback(() => {
    const url = pathname.split('/screen')[0]

    navigate(url)
  }, [navigate, pathname])

  useEffect(() => {
    const onKeyDownHandle = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (activeHeuristicId) {
          setActiveHeuristicId('')
        }
      }
    }

    document.addEventListener('keydown', onKeyDownHandle)

    return () => document.removeEventListener('keydown', onKeyDownHandle)
  }, [activeHeuristicId])

  const encodedProjectMeta = localStorage.getItem(`metaData-${projectId}`)
  const decodedProjectMeta: RemoteProjectMeta | null = encodedProjectMeta
    ? JSON.parse(encodedProjectMeta)
    : null

  const { data: platforms } = usePlatformsQuery()
  const projectPlatform = platforms?.find(
    (item) => item.id === decodedProjectMeta?.platformId
  )

  const isMobile =
    (projectPlatform?.name?.includes('obile') ||
      projectPlatform?.name?.includes('обил')) ??
    false

  const { data: heuristicsAndTags } = useHeuristicsAndTags(projectId)

  const heuristic = heuristicsAndTags?.heuristicsResponse.find(
    (heuristicObject) => heuristicObject.id === activeHeuristicId
  )

  const recommendation: any = Array.isArray(
    heuristic?.field_recommendation_how_to_fix
  )
    ? heuristic?.field_recommendation_how_to_fix?.[0]
    : null

  return (
    <div className={styles.wrapper}>
      <ProjectHead />
      <div className={styles.assessmentWrapper}>
        {screenId && <AssessmentHead screenId={screenId} goToFlow={goToFlow} />}
        {screenId && (
          <ScreenCanvas
            screenId={screenId}
            fragmentId={fragmentId}
            setFragmentId={setFragmentId}
          />
        )}
        {projectData &&
          projectId &&
          screenId &&
          heuristic &&
          recommendation && (
            <ReportDetailsContent
              isMobile={isMobile}
              heuristic={heuristic}
              projectId={projectId}
              recommendation={recommendation}
              className={styles.reportDetailsContent}
              onClose={() => setActiveHeuristicId('')}
            />
          )}
        {screenId && projectId && (
          <AssessmentPanel
            screenId={screenId}
            fragmentId={fragmentId}
            setFragmentId={setFragmentId}
            tab={tab}
            setTab={setTab}
            projectId={projectId}
            activeHeuristicId={activeHeuristicId}
            setActiveHeuristicId={setActiveHeuristicId}
          />
        )}
      </div>
    </div>
  )
}
