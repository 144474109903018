import { Heuristic } from 'entities/assessment'
import s from './ReportDetailsHeuristicInfo.module.css'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BookIcon } from '../../../../assets/book.svg'
import { ReactComponent as MobileIcon } from '../../../../assets/mobile-notch.svg'
import { ReactComponent as DesktopIcon } from '../../../../assets/display.svg'

interface ReportDetailsHeuristicInfoProps {
  heuristic: Heuristic
  onOpenDetails: VoidFunction
  isNegative: boolean
}

export const ReportDetailsHeuristicInfo = ({
  heuristic,
  isNegative,
  onOpenDetails,
}: ReportDetailsHeuristicInfoProps) => {
  const { t } = useTranslation()

  const negativeName = heuristic?.nameNegative || heuristic?.name
  const heuristicName = isNegative ? negativeName : heuristic?.name

  const isDesktopApplicable = heuristic?.applicability?.includes('desktop')
  const isMobileApplicable = heuristic?.applicability?.some((item) =>
    item?.includes('mobile')
  )

  return (
    <div className={s.wrapper}>
      <div className={s.headerWrapper}>
        <Typography className={s.header}>{t('heuristic')}</Typography>
        <div className={s.applicabilityIcons}>
          {isDesktopApplicable && (
            <div className={s.applicabilityIconWrapper}>
              <DesktopIcon />
            </div>
          )}
          {isMobileApplicable && (
            <div className={s.applicabilityIconWrapper}>
              <MobileIcon />
            </div>
          )}
        </div>
      </div>
      <Typography className={s.title}>{heuristicName}</Typography>
      <div className={s.moreLinkWrapper} onClick={onOpenDetails}>
        <BookIcon height={14} />
        <Typography className={s.moreText}>{t('moreDetails')}</Typography>
      </div>
    </div>
  )
}
